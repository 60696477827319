<template>
  <div>
    <img :src="require('@/assets/review/fake_shop_list.png')" class="img-fluid" />
  </div>
</template>

<script>
export default {
  name: 'ReviewShopList',
  data() {
    return {
      
    };
  },
  props: {
  },
  components: {
  },
  computed: {
    
	},
  watch: {
  },
  mounted() {
    
	},
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
  
</style>
